.main-box {
    background-color: #ffffff;
    height: fit-content;
    width: 100vw;
    margin-top: 10px;
}
p {
    color: #067fd0;
    font-weight: bold;
    margin-top: 2%;
    margin-left: 2%;
}

button {
    margin: 0px;
    border: 0px;

    background-color: white;

    color: #067fd0;
    font-weight: bold;
}

.dropdown {
    border: 0;
    background-color: #e2e3e5;
    color: #067fd0;
    font-weight: bold;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 96%;
    border: 1px solid #dddddd;
    background-color: white;
    margin: 2%;
    font-size: 1em;
}
thead {
    background-color: #e2e3e5;
}

td,
th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.form-group {
    margin-bottom: 0;
}
.btn-dropdown button {
    width: 100%;
}
.w12 {
    width: 12%;
}
.w10 {
    width: 10%;
}
.w70 {
    width: 70%;
}
.w20{
    width: 20%;
}
.f12{
    font-size: 12px;
}
.f13{
    font-size: 13px;
}
.f15{
    font-size: 15px;
}
.tableCss {
    overflow: auto;
}

.qualificationTableCss {
    margin-top: 10px;
    overflow: auto;
    white-space: nowrap;
    border: 1px solid rgb(228 221 221);
}
.paraCss {
    border-bottom: 1px solid gray;
    margin: 8px;
}

.w250 {
    width: 250px;
}
.w400 {
    width: 400px;
}
.w350 {
    width: 350px;
}
.cropImgCss {
    bottom: 0px;
    left: 2%;
}

.uploadImgPhotoCss {
    width: 130px;
    height: 100px;
    border: 1px solid gray;
    background-color: #f5f4f4;
}

.pb35 {
    padding-bottom: 35px;
}

.setImgCss {
    position: absolute;
    bottom: 0px;
    left: 30%;
}

.cancelSetImgCss {
    position: absolute;
    bottom: 0px;
    left: 40%;
}

.m11 {
    margin: 11px;
}

.mr96 {
    margin-right: 96px;
}

.borderCss {
    border: 1px solid #ccc;
}

.l10 {
    left: 10px;
}

.closeButton {
    background-color: transparent;
    color: gray;
    border: none;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 10px;
  }
  