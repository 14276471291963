.form-buffer {
    margin: 30px 0px;
    background-color: #067FD0;  
  }  
  .bounce {
    height: 180px;
    overflow: hidden;
    position: relative;
    text-align: left;  
}
.bounce p {
  position: absolute;
  width: 100%;
  margin: 0;
  line-height: 50px;
  text-align: left;
  transform: translateY(250px);
  animation: bouncing-text 10s linear infinite;
}
.bounce p:hover {
  animation-play-state: paused;
}

@keyframes bouncing-text {
  0% {
    transform: translateY(250px);
  }

  100% {
    transform: translateY(-100%);
  }
}

.ForgotEmailMobileForm {
  margin-top: 50px;
}

.EmailIdAndMobileNoLabelCss {
  position: relative;
  top: 12px;
  right: 30px;
  margin-right: 10px;
}

.f11 {
  font-size: 11px;
}

.borderBottomCss {
  border-bottom: 1px solid grey;
}

.ml60 {
  margin-left: 60px;
}

.ml90 {
  margin-left: 90px;
}

.f20 {
  font-size: 20px;
}