body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  background: #F8FAFC 0% 0% no-repeat padding-box;
}

.bg-color-first{
  background: #067FD0 0% 0% no-repeat padding-box;
  opacity: 1;
}

.bg-color-second{
  background: #2B70C3 0% 0% no-repeat padding-box;
  opacity: 1;
}

.bg-color-third{
  background: #0f4485 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #FFFFFF;
}

.text-third-nav{
  text-align: center;
  font: normal normal normal 18px/33px Open Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.text-second-nav{
  text-align: center;
  font: normal normal normal 20px/33px Open Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.subtext-second-nav{
  text-align: center;
	font: normal normal normal 14px/22px Open Sans;
	letter-spacing: 0px;
	color: #FFFFFF;
	opacity: 1;
}

.headerImgCss {
  position: relative;
  left: 65px;
}