.background-field {
    background: #f1f1f1 0% 0% no-repeat padding-box;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    opacity: 1;
    margin-bottom: 10px;
    padding-bottom: 15px;
    padding-top: 15px;
}
.register-button{
    background: #0057C1 0% 0% no-repeat padding-box;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

}

.bold-text {
    text-align: center !important;
    letter-spacing: 0px;
    color: #0C57C1;
    opacity: 1;
    font-weight: bold;
    font-size: 18px;
}
.helpline-text{
    text-align: left !important;
    letter-spacing: 0px;
    color: #0C57C1;
    opacity: 1;
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 18px;

}
.normal-text {
    text-align: center !important;
    font: normal normal 18px/27px Open Sans;
    letter-spacing: 0px;
    color: #0C57C1;
    opacity: 1;
}

.center-div{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.errorText{
    color: red;
    font-weight: normal;
    margin-top: 0;
    margin-left: 0;
}

.ml15 {
    margin-left: -15px;
}

.mr15 {
    margin-right: -15px;
}