.buttonAlign{
    position: relative;
    left: 35%;
    margin-top: 20px;
}

.tab-background{
background: #F2F2F2 0% 0% no-repeat padding-box;
border-radius: 4px 4px 0px 0px;
opacity: 1;
}
  
.react-datepicker-wrapper {
    width: 100%;
}
