.advertisement_details_firstrow{
    line-height: 20px;
    border: 1px solid rgb(184, 182, 182);
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: white;
    padding-top: 18px;
    padding-left: 15px;
    border-radius: 2px;
  }
  .advertisement_container{
      padding-left: 10px;
      padding-right: 10px;
  }
  .advertisement_details_Eligibility{
    line-height: 20px;
    border: 1px solid rgb(184, 182, 182);
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 2px;
  }
  .vl {
    border-left: 6px solid green;
    height: 500px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
  }
  .pad_0{
    padding: 0 0 0 0
  }
  .preference_no_textbox{
    position: absolute;
    width: 93%;
  }
  .mar_LeftRight_0{
    margin-right: 0px;
     margin-left:0px
  }
  .textalign_right{
    text-align: right;
  }
  .button_reset_apply{
    margin: 5px;
     width: 45%;
    font-size: 12px;
  }
  .pad_top_bottom_8{
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .mar_top_bottom_8{
    margin-top: 8px;
    margin-bottom: 8px;
  }
  h1{
    font-weight: 600;
    text-transform: uppercase;
  }
  h2{
    font-weight: 400;
  }
  h3{
    font-weight: 600;
    text-transform: uppercase;
  }
  h4{
    font-weight: 600;
  }
  h5{
    font-size: 14px;
    font-weight: 600;
  }
  h6{
    font-size: 12px;
    font-weight: 600;
  }
  .content-row{
    display: block;
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
    padding: 8px 0;
  }
  .content-row:first-child{
    padding-top: 0;
  }
  .content-row:last-child{
    border-bottom:0;
    padding-bottom: 0;
  }
  .content-row h6{
    display: inline-block;
    margin: 0 6px 0 0;
    padding: 0;
  }
  .content-row span{
    display: inline-block;
  }
  .content-row .btn{
    margin-top: -3px;
  }
  .noteColor{
    color: #007bff;
  }
  .img-profile{
    max-width: 100%;
    height: 75%;
    max-height: 75%;
  }
  .pointer-class{
    color: #0075ff;
    cursor: pointer
  } 
  .pre-wrap {
    white-space: pre-wrap;
  }
  .w65 {
    width: 65%;
  }
  .w60 {
    width: 60%;
  }
  .review_btn_css {
    width: auto;
    font-size: 11px;
  }
  .document_list_css {
    font-size: 18px;
    color: #007bff;
    margin-right: 5px;
    cursor: pointer;
  }
  .m17 {
    margin-left: 17px;
  }

  .ml37{
    margin-left: -37px;
  }