.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.row.print_block {
  padding: 0 15px;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  background-color: #0075a2;
  color: white;
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.disabled-link {
  pointer-events: none;
  color: grey;
}

.post-pref-item .arrange-arrows {
  opacity: 0;
}
.post-pref-item:hover .arrange-arrows {
  opacity: 1;
}
.arrange-arrows .fa {
  cursor: pointer;
}

.appLoader {    
  background-color: rgba(255, 255, 255, 0.7);
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  text-align: center;
  z-index: 9999;
  padding-top:25%;
  font-size: 15px !important;
  color: #0075a2 !important;
};

.appLoader spinner {    
  width: 50px;
  height: 50px;       
};
.retotalingSection .tab-content>.active {
  display: block;
  overflow: hidden;
  min-height: 300px;
  overflow-y: auto;
}