.Note{
  color:#007bff;
  text-align: left;
  font-size: 11px;
  float: left;
}
.Note-color{
  color: #007bff;
}
.RadioText{
    margin-left: 20px;
    text-align: left;
    margin-bottom: 0;  
}
.otherFormMarginBottom{
  margin-bottom: 10px;
}
.imageSection
{
  position: relative;
}
.errorMsg
{
  float: left;
    width: 100%;
    font-size: 13px;
    clear: both;
    margin-bottom: 15px;
    color: red;
}
.removeCandidateImage,.removeSignatureImage {
  background: red;
    padding: 0px 5px;
    color: #fff;
    font-weight: bold;
    position: absolute;
    left: 113px;
    top: 0px;
    cursor: pointer;
    font-size: 12px;
}

.LeftText{
  float: left;
  text-align: left;
  margin-bottom: 0;
  margin-top: 10px ;
}
.table-sm td, .table-sm th {
  padding: .3rem !important;
}

.bottomHeight{
  height: 100px;
  position: relative;
  left: 0;
  bottom: 0;
}

.btn-dropdown button+div{
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}
.btn-dropdown button{
  width: 100%;
}

.redColor{
 color: red;
}
.word-break{
  word-break: break-word;
}
.form-group {
  margin-bottom: 10px !important;
}

.table{
  font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 96%;
    border: 1px solid #dddddd;
    background-color: white;
    font-size: 1em;
}

.table td {
  padding: 5px;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table th {
  padding: 9px;
  font-size: 11px;
}
.addSportsCertificate {
  display: flex;
  justify-content: flex-end;
}
.uploadImageLabelDiv {
  border: 1px solid;
  padding-right: 30px;
  padding-left: 30px;
  position: relative;
  bottom: 19px;
}
.uploadImageLabelImg {
  border: 1px solid;
  padding-right: 30px;
  padding-left: 30px;
}

.uploadPhotoInstruction{
  text-align: left;
  margin-left: 2px;
  margin-bottom: 0;
  color: #007bff;
}

.mt12{
  top: 12px;
}

.boxShadowCss {
  box-shadow: 0 0 5px rgb(0 0 0 / 10%), inset 0 -2px 0 #dfe1e2;
}

.mb35 {
  margin-bottom: 35px;
}

.moduleHideDiv {
  background-color: #ffff;
  padding: 20px;
  margin-top: 20px;
}