
.devanagariList {list-style-type: devanagari;}
.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.position-ref {
  position: relative;
}
.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}
.content {
  text-align: center;
}
.title {
  font-size: 84px;
}
.m-b-md {
  margin-bottom: 30px;
}
.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}
.t-left {
  text-align: left;
}
table p {
  margin-bottom: 4px;
  font-size: 12px;
}
.header-bd {
  padding: 10px;
}
.table-first tr td {
  line-height: 22px;
  padding-top: 1px;
  border: 1px ridge #000;
  border-style: none ridge ridge none;
}
.table-first th{
  border: 1px ridge #000;
  border-style: none ridge ridge none;
}
.table-sec th{
  border: 1px ridge #000;
  border-style: none ridge ridge none;
 }
.table-sec tr td  {
  padding: 0px 6px;
  line-height: 18px;
  padding-bottom: 4px;
  border: 1px ridge #000;
  border-style: none ridge ridge none;
}
.bg-f1 {
  background: #f1f1f1;
  color: #000;
  font-weight: 600;
}
.table-responsive {
  display: table;
  width: 97%;
  border: 1px ridge #000;
  border-style: ridge none none ridge;
}
.heading-pdf {
  margin: 0;
  font-size: 22px;
}
.bold-f {
  font-weight: 600;
}
ol li{
   font-size: 12px;
}
ol li ol{
   padding-left: 10px;
}
.imgwidth{
   width: 100px;
   height: 100px;
}
.imgwidthSign{
   width: 100px;
   height: 50px;
}
.admissionCertificate{
  font-size: 16.5px;
}
.common{
  font-size: 11px;
  border-collapse: collapse;
}
.commnFontSize{
  padding: 2px;
  font-size: 9px;
  color: black ;
  text-align: left;
}
.examTime{
  font-weight: 500;
  color: black ;
}
.centered-right {
  display: flex;
  justify-content: flex-end;
}
.centered-content {
  justify-content: right;
  text-align: center;
  padding-right: 10px;
}
.header-bd{
  padding: 10px;
  border: 1px solid #000;
}

.interviewLetterBorderCss{
  border: 1px solid black;
}

.interviewLetterTableCss{
  margin-top: 10px;
  overflow: auto;
  white-space: nowrap;
}

.fontSize14{
  font-size: 14px;
}

.interviewLetterTableDataCss{
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.custom-paragraph {
  /* color: #343a40; */
  color: #4a4a4a;
  /* font-weight: bold; */
  page-break-inside: avoid;
}

.pr70{
  padding-right: 70px;
}

.pr80{
  padding-right: 80px;
}

.pr30{
  padding-right: 30px;
}

.custom-list-item {
  margin-left: 20px;
  color: #4a4a4a;
  /* font-weight: bold; */
  page-break-inside: avoid;

}